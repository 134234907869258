import GazLogo from "@/images/clients-list/gaz.svg";
import HyundaiLogo from "@/images/clients-list/hyundai.svg";
import MastercardLogo from "@/images/clients-list/mastercard.svg";
import MirLogo from "@/images/clients-list/mir.svg";
import MTCLogo from "@/images/clients-list/mtc.svg";
import OzonLogo from "@/images/clients-list/ozon.svg";
import PepsiLogo from "@/images/clients-list/pepsi.svg";
import PurinaLogo from "@/images/clients-list/purina.svg";
import SberLogo from "@/images/clients-list/sber.svg";
import Tele2Logo from "@/images/clients-list/tele2.svg";
import VisaLogo from "@/images/clients-list/visa.svg";
import VtbLogo from "@/images/clients-list/vtb.svg";
import YandexLogo from "@/images/clients-list/yandex.svg";
import Image from "next/image";
import type { CSSProperties } from "react";
import styles from "./ClientList.module.css";

export function ClientListItem({ isReversed }: { isReversed?: boolean }) {
  const style: CSSProperties = {
    display: "flex",
    flexDirection: isReversed ? "row-reverse" : "row",
  };

  return (
    <div style={style}>
      <div className={styles.clientListItem}>
        <Image src={MTCLogo} alt="" />
      </div>
      <div className={styles.clientListItem}>
        <Image src={SberLogo} alt="" />
      </div>
      <div className={styles.clientListItem}>
        <Image src={Tele2Logo} alt="" />
      </div>
      <div className={styles.clientListItem}>
        <Image src={GazLogo} alt="" />
      </div>
      <div className={styles.clientListItem}>
        <Image src={PepsiLogo} alt="" />
      </div>
      <div className={styles.clientListItem}>
        <Image src={MirLogo} alt="" />
      </div>
      <div className={styles.clientListItem}>
        <Image src={HyundaiLogo} alt="" />
      </div>
      <div className={styles.clientListItem}>
        <Image src={PurinaLogo} alt="" />
      </div>
      <div className={styles.clientListItem}>
        <Image src={VtbLogo} alt="" />
      </div>
      <div className={styles.clientListItem}>
        <Image src={MastercardLogo} alt="" />
      </div>
      <div className={styles.clientListItem}>
        <Image src={OzonLogo} alt="" />
      </div>
      <div className={styles.clientListItem}>
        <Image src={VisaLogo} alt="" />
      </div>
      <div className={styles.clientListItem}>
        <Image src={YandexLogo} alt="" />
      </div>
    </div>
  );
}
