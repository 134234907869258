"use client";

import { services } from "@/lib/servicesData";
import { useState } from "react";
import { CardServices } from "./CardServices/CardServices";
import styles from "./OurServices.module.css";

export function OurServices() {
  const [active, setActive] = useState<number>(1);

  return (
    <section id={"our-services"} className={styles.ourServices}>
      <header className={"container"}>
        <h2>
          Наши <br />
          <span>сервисы</span>
        </h2>
      </header>
      <ul className={styles.ourServicesCardsList}>
        {services.map((card) => {
          return (
            <CardServices
              key={card.id}
              id={card.id}
              title={card.title}
              technologies={card.technologies}
              active={active}
              setActive={setActive}
            />
          );
        })}
      </ul>
    </section>
  );
}
