import { textWithPrepositionSplice } from "@/lib/textWithPrepositionSplice";
import { Children, cloneElement, isValidElement, type ReactNode } from "react";


export const FormattedText = ({ children }: { children: ReactNode }) => {
    const formatTextNodes = (node: ReactNode): ReactNode => {
        if (typeof node === 'string') {
            return textWithPrepositionSplice(node);
        }
        if (isValidElement<{children: ReactNode}>(node)) {
            return cloneElement(node, node.props, ...Children.toArray(node.props.children).map(formatTextNodes));
        }
        return node;
    };

    return <>{Children.toArray(children).map(formatTextNodes)}</>;
};

