__turbopack_context__.v({
  "active": "CardServices-module__IV5AZG__active",
  "cardContent": "CardServices-module__IV5AZG__cardContent",
  "cardServices": "CardServices-module__IV5AZG__cardServices",
  "hoverTitle": "CardServices-module__IV5AZG__hoverTitle",
  "hr": "CardServices-module__IV5AZG__hr",
  "technologiesList": "CardServices-module__IV5AZG__technologiesList",
  "technology": "CardServices-module__IV5AZG__technology",
  "title": "CardServices-module__IV5AZG__title",
  "unhoverTitle": "CardServices-module__IV5AZG__unhoverTitle",
});
