export const textWithPrepositionSplice = (text: string) => {
  const regex =
    /\s(или|в|а|для|на|и|с|к|о|по|за|во|под|над|перед|между|около|-|от|до|что|не|in|on|at|to|for|with|from|by|about|as|of|like|through|after|before|between|among|against|over|under|near|across|during|until|since|without|within|beneath|beside|beyond|into|off|onto|up|down|inside|outside|toward|despite|till|except|but|and|or|nor|so|yet|because|since|although|though|if|unless|while|whereas|wherever|whenever|however|whoever|whichever|whatever|whether|than|as if|as though|even though|in order that|so that|provided that|assuming that|lest|now that|once that|on condition that|seeing that|supposing that)\s/gi;

  const regexHyphen = /-/g;
  const regexSemicolon = /;/g;
  const regexDoubleArrow = />>/g;
  const regexSingleArrow = />/g;

  const addSplice = (match: string) => {
    return ` ${match.trim()}\u00A0`;
  };

  const formattedText = text
    .replace(regex, addSplice)
    .replace(regexHyphen, "‑")
    .replace(regexSemicolon, ";\n")
    .replace(regexDoubleArrow, "\n\n") // Двойной перенос
    .replace(regexSingleArrow, "\n"); // Одинарный перенос

  return formattedText;
};
