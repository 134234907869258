import { FormattedText } from "../FormattedText/FormattedText";
import styles from "./SuperPowers.module.css";

export function SuperPowers() {
  return (
    <section
      id={"super-power"}
      className={styles.superPowers}
      aria-label="Наши ключевые преимущества"
    >
      <header className={"container"}>
        <h2>
          Наша <br />
          <span>суперсила</span>
        </h2>
      </header>
      <ul className={`container ${styles.superPowerWrapper}`}>
        <li className={styles.superPower}>
          <div className={styles.superPowerNumber}>
            <span aria-label="number 01">01</span>
            <span aria-hidden={true}>\</span>
          </div>
          <FormattedText>
            <p className={styles.superPowerDescription}>
              Делаем коммуникации бренда с пользователям вовлекающими и
              интересными
            </p>
          </FormattedText>
        </li>
        <li className={styles.superPower}>
          <div className={styles.superPowerNumber}>
            <span aria-label="number 02">02</span>
            <span aria-hidden={true}>\</span>
          </div>
          <FormattedText>
            <p className={styles.superPowerDescription}>
              Применяем комплексный подход в реализации проектов: от концепции
              до реализации
            </p>
          </FormattedText>
        </li>
        <li className={styles.superPower}>
          <div className={styles.superPowerNumber}>
            <span aria-label="number 03">03</span>
            <span aria-hidden={true}>\</span>
          </div>
          <FormattedText>
            <p className={styles.superPowerDescription}>
              Качественно реализуем и запускаем проекты даже в супер сжатые
              сроки
            </p>
          </FormattedText>
        </li>
      </ul>
    </section>
  );
}
