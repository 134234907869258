import Marquee from "react-fast-marquee";
import styles from "./ClientList.module.css";
import { ClientListItem } from "./ClientListItem";

export function ClientList() {
	return (
		<section id={"client-list"} className={styles.clientList}>
			<Marquee
				autoFill={true}
				pauseOnClick={true}
				className={styles.clientListContent}
			>
				<ClientListItem />
			</Marquee>
			<Marquee
				direction={"right"}
				autoFill={true}
				pauseOnClick={true}
				className={styles.clientListContent}
			>
				<ClientListItem isReversed={true} />
			</Marquee>
		</section>
	);
}
