"use client";

import "keen-slider/keen-slider.min.css";
import { useKeenSlider } from "keen-slider/react";
import { useEffect, useState } from "react";
import { SORTED_PROJECTS } from "src/lib/data";
import { ArrowButton, GoToProjectsButton } from "../ui/buttons";
import { SkeletonSlides } from "../ui/skeletons";
import { SlideProject } from "./SlideProject/SlideProject";
import styles from "./SliderProjects.module.css";

export function SliderProjects() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loadedComponent, setLoadedComponent] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    initial: 0,
    loop: false,
    mode: "snap",
    slides: {
      perView: "auto",
      spacing: 30,
    },
    breakpoints: {
      "(max-width: 1024px)": {
        slides: { perView: 1, spacing: 30 },
      },
      "(min-width: 535px)": {
        slides: { perView: "auto", spacing: 30 },
      },
    },
    renderMode: "performance",
    updated: () => loadedComponent,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },

    created() {
      setLoaded(true);
    },
  });

  useEffect(() => {
    setLoadedComponent(true);
  }, []);

  return (
    <section id={"projects"} className={styles.projects}>
      <header className={"container"}>
        <h2>
          Помогли запустить <br />
          <span>{">"} 100 проектов</span>
        </h2>
      </header>
      <div className={`container ${styles.sliderWrapper}`}>
        {loadedComponent && (
          <div ref={sliderRef} className={"keen-slider"}>
            {SORTED_PROJECTS?.map((project) => {
              return <SlideProject key={project.id} {...project} />;
            })}
          </div>
        )}
        {!loadedComponent && (
          <div style={{ display: "flex", gap: 30 }} className={"keen-slider"}>
            <SkeletonSlides />
            <SkeletonSlides />
            <SkeletonSlides />
            <SkeletonSlides />
            <SkeletonSlides />
          </div>
        )}
        {loaded && instanceRef.current && (
          <div className={styles.dots}>
            {[
              ...Array(instanceRef.current.track.details.slides.length).keys(),
            ].map((idx) => {
              return (
                <span
                  aria-label={`go to slide ${idx}`}
                  role="button"
                  key={idx}
                  onClick={() => {
                    instanceRef.current?.moveToIdx(idx);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" || e.key === " ") {
                      e.preventDefault();
                      instanceRef.current?.moveToIdx(idx);
                    }
                  }}
                  className={`${styles.dot} ${
                    currentSlide === idx ? styles.active : ""
                  }`}
                />
              );
            })}
          </div>
        )}
        <div className={styles.buttonsWrapper}>
          <GoToProjectsButton />
          {loaded && instanceRef.current && (
            <div className={styles.arrowButtons}>
              <ArrowButton
                left={true}
                onClick={(e) => {
                  e.stopPropagation();
                  instanceRef.current?.prev();
                }}
              />
              <ArrowButton
                onClick={(e) => {
                  e.stopPropagation();
                  instanceRef.current?.next();
                }}
              />
            </div>
          )}
        </div>
      </div>
    </section>
  );
}
