"use client";

import { ClientList } from "@/components/ClientsList/ClientList";
import { Hero } from "@/components/Hero/Hero";
import { OurServices } from "@/components/OurServices/OurServices";
import { SliderProjects } from "@/components/SliderProjects/SliderProjects";
import { SuperPowers } from "@/components/SuperPowers/SuperPowers";

export default function Home() {
  return (
    <>
      <Hero />
      <SliderProjects />
      <ClientList />
      <SuperPowers />
      <OurServices />
    </>
  );
}
