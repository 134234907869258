import { Tag } from "@/components/ui/tag";
import type { ServicesProps } from "@/types/componentsTypes";
import styles from "./CardServices.module.css";

export const CardServices = ({
  id,
  title,
  technologies,
  active,
  setActive,
}: ServicesProps) => {
  return (
    <li
      className={`${styles.cardServices}  ${
        active === id ? styles.active : ""
      }`}
      onMouseEnter={() => setActive?.(id)}
      onMouseLeave={() => setActive?.(0)}
    >
      <div className={`container ${styles.cardContent}`}>
        <div className={styles.title}>
          <span aria-label={`number ${id}`}>{`0${id}`}</span>
          <span aria-hidden={true}>\</span>
          <span aria-label={title}>{title}</span>
        </div>
        <div className={styles.technologiesList}>
          {technologies?.map((item) => {
            return <Tag key={item.id}>{item.tabName}</Tag>;
          })}
        </div>
      </div>
      <hr className={styles.hr} />
    </li>
  );
};
