__turbopack_context__.v({
  "contentDescription": "SlideProject-module__hIYS1q__contentDescription",
  "contentDetails": "SlideProject-module__hIYS1q__contentDetails",
  "contentImage": "SlideProject-module__hIYS1q__contentImage",
  "contentInfo": "SlideProject-module__hIYS1q__contentInfo",
  "contentOverlay": "SlideProject-module__hIYS1q__contentOverlay",
  "contentTag": "SlideProject-module__hIYS1q__contentTag",
  "contentTags": "SlideProject-module__hIYS1q__contentTags",
  "contentTitle": "SlideProject-module__hIYS1q__contentTitle",
  "slide": "SlideProject-module__hIYS1q__slide",
  "slideContent": "SlideProject-module__hIYS1q__slideContent",
});
