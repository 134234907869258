import type { ComponentPropsWithoutRef, ReactNode } from "react";
import styles from "./tag.module.css";

type TagProps = ComponentPropsWithoutRef<"span"> & {
  children: ReactNode;
}

export const Tag = ({ children }: TagProps) => {
	return <span className={styles.tag}>{children}</span>;
};
