import type { ServicesProps } from "@/types/componentsTypes";

export const services: ServicesProps[] = [
	{
		id: 1,
		title: "Креатив",
		technologies: [
			{
				tabName: "Идеи",
				tabDescription:
					"Создадим идею, которая гармонично впишется в общую креативную концепцию кампании. Эта идея подчеркнёт ключевые посылы и усилит восприятие вашего бренда.",
				id: 1,
			},
			{
				tabName: "Технологии",
				tabDescription:
					"Предложим технологии, идеально подходящие для реализации вашей идеи. Подберём оптимальные инструменты, чтобы максимально раскрыть потенциал концепции. Обеспечим эффективное и современное воплощение задумки на всех этапах проекта.",
				id: 2,
			},
			{
				tabName: "Прототипы",
				tabDescription:
					"Подготовим прототипы, чтобы наглядно предемонстрировать преимущества идеи проекта. Обеспечим визуальное и интерактивное тестирование для оптимизации дальнейшей разработки проекта.",
				id: 3,
			},
		],
		serviceDescription:
			"Формируем ключевые креативные концепции, используя интерактивные и web инструменты для глубокой вовлечённости пользователей.",
	},
	{
		id: 2,
		title: "Дизайн",
		technologies: [
			{
				tabName: "UI/UX Интерфейсы",
				tabDescription:
					"Оказываем услуги по разработке и отрисовке UI/UX интерфейсов. Создаём интуитивные и визуально понятные интерфейсы для улучшения пользовательского опыта. Обеспечиваем адаптивность и функциональность для любых устройств и платформ.",
				id: 1,
			},
			{
				tabName: "Моделирование",
				tabDescription:
					"Создаём детализированные и визуально привлекательные 3D модели, адаптированные под специфические требования. Обеспечиваем качественную проработку деталей для максимального эффекта в вашем проекте.",
				id: 2,
			},
			{
				tabName: "2D/3D Анимация",
				tabDescription:
					"Разрабатываем анимационные решения для различных целей: от презентаций до игр. Индивидуально подходим к каждому проекту, учитывая ваши предпочтения и требования.",
				id: 3,
			},
		],
		serviceDescription:
			"Отвечаем за визуальную составляющую вашего digital проекта, обеспечивая чистый и актуальный дизайн. Создаём графику и интерфейсы, которые усиливают ваше сообщение и улучшают пользовательский опыт.",
	},
	{
		id: 3,
		title: "Разработка",
		technologies: [
			{
				tabName: "Игры",
				tabDescription:
					"Придумываем игровые механики и создаем игры под разные платформы для различных целей.",
				stack: ["Unity", "Unreal", "PhaserJS"],
				id: 1,
			},
			{
				tabName: "Desktop and mobile app",
				tabDescription:
					"Разрабатываем приложения для сенсорных экранов и мобильных устройств.",
				stack: ["TouchDesigner", "Air", "Unity"],
				id: 2,
			},
			{
				tabName: "Web",
				tabDescription: "Разрабатываем сайты, веб-приложения и веб-сервисы любой сложности",
				stack: [
					"TypeScript",
					"React",
					"Next",
					"Remix",
					"Node",
					"Hono",
					"Elixir",
					"PostgreSQL",
					"php",
				],
				id: 3,
			},
			{
				tabName: "WebAR",
				tabDescription:
					"Разрабатываем веб приложения с дополненной реальностью.",
				stack: ["8thWall", "Zappar", "Onirix"],
				id: 4,
			},
			{
				tabName: "AR",
				tabDescription:
					"Разрабатываем мобильные и windows приложения с дополненной реальностью.",
				stack: ["Unity", "Vuforia", "ARCore", "ARKit"],
				id: 5,
			},
			{
				tabName: "VR",
				tabDescription:
					"Создаем интерактивные инсталляции, которые вовлекают зрителей в процесс, позволяя взаимодействовать с пространством и объектами.",
				stack: ["Unreal", "Unity"],
				id: 6,
			},
			{
				tabName: "Мультимедийные инсталляции",
				tabDescription:
					"Разрабатываем VR-продукты, погружающие пользователей в виртуальные миры. Используем передовые технологии для создания игр, обучающих программ и тренажеров.",
				stack: [
					"TouchDesigner",
					"Unity",
					"Контроллеры",
					"Arduino",
					"Led",
					"Сенсоры, Кнопки",
					"Роботы",
				],
				id: 7,
			},
			{
				tabName: "Чат-боты",
				tabDescription:
					"Занимаемся разработкой чат-ботов, которые автоматизируют коммуникацию и упрощают процессы взаимодействия с пользователями.",
				stack: ["Telegram-API-Bot", "TypeScript"],
				id: 8,
			},
		],
		serviceDescription:
			"Занимаемся разработкой программного обеспечения для интерактивных решений, охватывая различные направления от веб-разработки до игровых приложений. Обеспечиваем интеграцию современных технологий для достижения высокого уровня вовлеченности пользователя.",
	},
	{
		id: 4,
		title: "Инжиниринг",
		technologies: [
			{
				tabName: "Оборудование",
				tabDescription:
					"Поможем подобрать, приобрести и арендовать все необходимое оборудование для реализации проекта.",
				id: 1,
			},
			{
				tabName: "Проектирование",
				tabDescription:
					"Подготовим документацию и проект для изготовления нестандарных технический изделий и оборудования.",
				id: 2,
			},
			{
				tabName: "Производство",
				tabDescription:
					"Произведем необходимое оборудование для вашего проекта от единичных экземпляров до больших тиражей.",
				id: 3,
			},
			{
				tabName: "Продюсирование",
				tabDescription:
					"Помогаем на всех этапах проекта: от идее до реализации. Следим за выполение работ в срок.",
				id: 4,
			},
		],
		serviceDescription:
			"Создаем технологические решения: проектируем, консультируем и оказываем поддержку в разработке.",
	},
];
