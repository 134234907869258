import { RoutesEnum } from "@/lib/routes";
import type { ProjectSlideProps } from "@/types/componentsTypes";
import Image from "next/image";
import Link from "next/link";
import styles from "./SlideProject.module.css";
import { useCursorVariantContext } from "src/context/cursor-variant-context";
import { FormattedText } from "@/components/FormattedText/FormattedText";

export function SlideProject({
  name,
  shortname,
  description,
  tags,
  path,
  img,
}: ProjectSlideProps) {
  const { linkEnter, linkLeave } = useCursorVariantContext();

  return (
    <div
      onMouseEnter={() => linkEnter()}
      onMouseLeave={() => linkLeave()}
      onClick={() => linkLeave()}
      {...linkLeave}
      className={`keen-slider__slide ${styles.slide}`}
    >
      <div className={styles.slideContent}>
        <Link href={`/${RoutesEnum.ALL_PROJECTS}/${path}`}>
          <div className={styles.contentOverlay} />
          <Image
            quality={95}
            loading="lazy"
            className={styles.contentImage}
            src={img[0]}
            alt={name}
            sizes="(max-width: 1920px) 500px 650px, (max-width: 1024px) 280px 365px"
            style={{
              objectFit: "contain",
            }}
            placeholder="blur"
          />
          <div className={styles.contentDescription}>
            <div className={styles.contentTags}>
              {tags?.map((tag) => {
                return (
                  <span key={tag} className={styles.contentTag}>
                    {tag}
                  </span>
                );
              })}
            </div>
            <p className={styles.contentTitle}>
              <FormattedText>{shortname}</FormattedText>
            </p>
          </div>
          <div className={styles.contentDetails}>
            <div className={styles.contentInfo}>
              <h3>
                <FormattedText>{name}</FormattedText>
              </h3>
              <p>
                <FormattedText>{description}</FormattedText>
              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
