import { Br } from "../ui/br";
import { nbsp } from "../ui/nbsp";
import styles from "./Hero.module.css";

export function Hero() {
	return (
		<section id={"hero"} className={styles.hero}>
			<header className={`container ${styles.heroTitle}`}>
				<h1>
					Студия
					<Br isMobile={true} /> <span>интерактивных</span> <br /> коммуникаций
				</h1>
				<p>
					Создаем цифровые решения <Br isMobile={true} /> для{nbsp}бизнеса в{nbsp}
					online/offline.
					<br /> Делаем промо и{nbsp}события интересными.
				</p>
			</header>
		</section>
	);
}
